import Head from 'next/head';

type PageTitleProps = {
  title: string;
};

const PageTitle: FC<PageTitleProps> = (props) => {
  const { title } = props;

  return (
    <Head>
      <title>Defease With Ease{title && ` - ${title}`}</title>
    </Head>
  );
};

export default PageTitle;
