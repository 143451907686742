import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    marginTop: theme => theme.spacing(-7),
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme => theme.spacing(5),
  },
  paper: {
    width: 654,
    padding: theme => theme.spacing(5),
  },
  textContainer: {
    fontWeight: 500,
    lineHeight: theme => theme.typography.pxToRem(24),
    textAlign: 'center',
    marginBottom: theme => theme.spacing(5),
  },
  title: {
    fontSize: theme => theme.typography.pxToRem(24),
    marginBottom: theme => theme.spacing(3),
  },
  text: {
    fontSize: theme => theme.typography.pxToRem(18),
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: 188,
  },
};

export default styles;
