import ErrorLayout from 'layouts/Error/ErrorLayout';

import { Button, Paper } from '@mui/material';
import { GetStaticProps } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Box from 'components/common/Box';
import PageTitle from 'components/common/PageTitle';

import styles from './styles';

const PageNotFound: Page = () => {
  const { t } = useTranslation('notFoundPage');

  const router = useRouter();

  const handleGoBack = () => {
    router.back();
  };

  const isButtonVisible = window.history.length > 1;

  return (
    <>
      <PageTitle title={t('title')} />
      <Box sx={styles.root}>
        <Paper sx={styles.paper}>
          <Box sx={styles.iconContainer}>
            <Image src='/icons/404.svg' height='60' width='60' alt="404" />
          </Box>
          <Box sx={styles.textContainer}>
            <Box sx={styles.title}>{t('pageNotFoundMessage')}</Box>
            <Box sx={styles.text}>{t('checkURLAddressMessage')}</Box>
          </Box>
          {isButtonVisible && (
            <Box sx={styles.buttonContainer}>
              <Button sx={styles.button} color='primary' variant='contained' onClick={handleGoBack}>
                {t('goBackButton')}
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
};

PageNotFound.Layout = ErrorLayout;

export const getStaticProps: GetStaticProps = async ({ locale }) => (
  {
    props: {
      ...(await serverSideTranslations(locale, ['notFoundPage'])),
    },
  }
)

export default PageNotFound;
