import { useCallback, useState } from 'react';

import { AuthHelper } from 'helpers/AuthHelper';

import { useRouter } from 'next/router';

import { useUser } from 'hooks/api';
import { useBreadcrumbsContext } from 'hooks/useBreadcrumbsContext';

import Box from 'components/common/Box';
import Breadcrumbs from 'components/common/Breadcrumbs';
import HeaderBar from 'components/common/HeaderBar';

import styles from './styles';

type Type = string | number;

interface BaseProps {
  title?: Type;
  isLostConnectionError: boolean;
  unprocessedError: string;
  isErrorNotificationShown: boolean;
}

const ErrorLayout: FC<BaseProps> = (props) => {
  const { children, title, isLostConnectionError, unprocessedError, isErrorNotificationShown } = props;
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const { breadcrumbs } = useBreadcrumbsContext();
  const { resetCurrentUser } = useUser();
  const router = useRouter();

  const handleMenuToggle = useCallback((event) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const handleLogOut = () => AuthHelper.logout(resetCurrentUser, router);

  return (
    <Box sx={styles.root}>
      <HeaderBar
        handleMenuToggle={handleMenuToggle}
        menuAnchor={menuAnchor}
        handleMenuClose={handleMenuClose}
        handleLogOut={handleLogOut}
        isLostConnection={isLostConnectionError}
        unprocessedError={unprocessedError}
        isErrorNotificationShown={isErrorNotificationShown}
      />
      {title && (
        <Box sx={styles.title}>
          {title}
        </Box>
      )}
      <Box sx={styles.breadcrumbs}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </Box>
      {children}
    </Box>
  );
};

export default ErrorLayout;
