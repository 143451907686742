import { SxStyles } from 'theme';

const styles: SxStyles = {
  root: {
    minHeight: '100%',
    background: theme => `linear-gradient(to bottom, ${theme.palette.catskillWhite} 40%, ${theme.palette.background.default} 40%);`,
  },
  appBar: {
    backgroundColor: 'text.primary',
    height: theme => theme.layouts.baseLayout.header.height,
  },
  toolBar: {
    minHeight: 48,
  },
  title: {
    fontSize: theme => theme.typography.pxToRem(29),
    marginBottom: 4,
  },
  logo: {
    position: 'absolute',
  },
  breadcrumbs: {
    position: 'relative',
    zIndex: 1,
    marginBottom: 1,
  },
  userMenuButton: {
    color: 'white',
    opacity: 0.7,
    whiteSpace: 'nowrap',
  },
  userMenuIcon: {
    width: 18,
    height: 18,
    marginLeft: theme => theme.spacing(0.5),
  },
  userMenu: {
    marginTop: theme => theme.spacing(5.5),
  },
  userMenuItem: {
    minHeight: 0,
    minWidth: 150,
  },
};

export default styles;
